import React, { useEffect, useRef, useState } from 'react';
import { useProgressContext } from '../../ContextProvider/ProgressContext';
import uojApi from '../../Common/Api/uojApi';
import 'remixicon/fonts/remixicon.css';

const FormCard = () => {
  const { setActiveState, getActiveState } = useProgressContext();

  const [formData, setFormData] = useState({
    name: '',
    voucherCode: '',
    identityNumber: '',
    documentType: 'Passport/NIC',

    payamount: null,
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [user, setUser] = useState({
    name: '',
    purpose: '',
    type:'',
    total: '',
  });

  const [isVoucherValid, setIsVoucherValid] = useState(true); //form validation
  const [authvoucher, setAuthVoucher] = useState(false); //after verified voucher state
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [paysuccess, setPaysuccess] = useState(null);
  const [payfailed, setPayfailed] = useState(null);

  const active = getActiveState();
  const [aid, setAid] = useState(active.id);

  useEffect(() => {
    setAid(active.id);
  }, [active]);

  const payamountRef = useRef();

  // post api call for 1 st stage
  const checkvoucher = async (vouchercode) => {
    try {
      const intvoucher = parseInt(vouchercode);
      const response = await uojApi.post(
        `/verify-voucher?voucher_code=${intvoucher}`
      );
      console.log('voucher response is', response);

      setAuthVoucher(true);
      setError(null);
      setSuccess(response.data.message);
      setUser({
        name: response.data.data.user_name,
        purpose: response.data.data.voucher,
        total: response.data.data.amount,
        type:response.data.data.user_type
      });
      console.log('user is ', user);
    } catch (error) {
      setError(error.response.data.message);
      setSuccess(null);
      console.error('Error while verifying voucher', error);
      console.error('Error while verifying voucher', error);

      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    } finally {
      console.log('verify finished');
    }
  };

  // final payment api call

  const paynowfunction = async (sta) => {
    try {
      const response = await uojApi.post(`/payment-status?status=${sta}`);
      console.log('paynow response is', response.data.message);
      setPaysuccess(response.data.message);
    } catch (error) {
      console.log(error);
      setPayfailed(error.response.data.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setIsDropdownOpen(false);
  };

  const handleVerify = () => {
    const isNumeric = /^[0-9]+$/.test(formData.voucherCode);
    if (formData.voucherCode.length === 16 && isNumeric) {
      setIsVoucherValid(true);
      checkvoucher(formData.voucherCode);
    } else {
      setIsVoucherValid(false);
    }
  };

  const handleClear = () => {
    setFormData({
      name: '',
      voucherCode: '',
      identityNumber: '',
      documentType: 'Passport/NIC',
    });
    setSuccess(null);
    setError(null);
    setIsVoucherValid(true);
    setAuthVoucher(false);
  };

  const handleamountClear = () => {
    setFormData({ ...formData, payamount: null });
    payamountRef.current.value = null;
    console.log('amount clear');
  };

  const handleNext = () => {
    const activeState = getActiveState();
    if (activeState && activeState.id < 4) {
      setActiveState(activeState.id + 1);
    }
    console.log('form data is ', formData);
  };

  const paynow = () => {
    const activeState = getActiveState();
    if (activeState && activeState.id < 4) {
      setActiveState(activeState.id + 1);
    }
    console.log('final data ', formData);
    console.log('final user', user);

    paynowfunction('success');
  };

  const handleNext2 = () => {
    const activeState = getActiveState();
    if (activeState && activeState.id < 4) {
      setActiveState(activeState.id + 1);
    }

    console.log('data from stage 2', formData);
  };

  const handleBack = () => {
    const activeState = getActiveState();
    if (activeState && activeState.id < 4) {
      setActiveState(activeState.id - 1);
    }
  };
   const handleBackHome = () => {
     const activeState = getActiveState();
     if (activeState && activeState.id < 4) {
       setActiveState(activeState.id - 1);
     }
     handleClear();

   };

  return (
    <div className='flex  items-center justify-center mt-10 font-Agbalumo'>
      <div className='bg-white text-sm lg:text-lg text-black rounded-md   border-2 border-gray-100  shadow-lg  p-2 md:p-8  lg:w-[50%]'>
        <h1 className='mb-8 text-xl  text-blue-700'>Pay Online</h1>

        {/* 1st stage */}
        {aid === 1 && (
          <>
            <div className='mb-8 '>
              <div className='mb-8 '>
                <div className='flex'>
                  <input
                    className='w-full  px-3 py-2 border rounded-l'
                    type='text'
                    id='voucherCode'
                    name='voucherCode'
                    placeholder='Voucher Code'
                    value={formData.voucherCode}
                    onChange={handleChange}
                    required
                  />
                  <button
                    className='bg-purple-500  text-white px-4 py-2 rounded-r'
                    onClick={handleVerify}
                  >
                    Verify
                  </button>
                </div>
                {!isVoucherValid && (
                  <p className='text-red-500'>
                    Voucher code must be a 16-digit number.
                  </p>
                )}
                {error && <p className='text-red-500'>{error}</p>}
                {success && <p className='text-green-700'>{success}</p>}
              </div>
              {authvoucher && (
                <div className='text-left font-[Rajdhani] font-bold  my-2 mb-5 shadow-lg p-3'>
                  <h1 className='text-blue-500'>
                    name: <span className='text-black'>{user.name}</span>
                  </h1>
                  <h1 className='text-blue-500'>
                    purpose: <span className='text-black'>{user.purpose}</span>
                  </h1>
                </div>
              )}

              <input
                className='w-full px-3 py-2 border rounded'
                type='text'
                id='name'
                name='name'
                placeholder='Name'
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className='lg:flex gap-2'>
              <div className='relative mb-8 lg:w-[25%] bg-white rounded'>
                <div
                  className='w-full px-3 py-2 border rounded cursor-pointer text-left  '
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {formData.documentType}
                </div>
                {isDropdownOpen && (
                  <div className='absolute top-12 left-0 right-0 bg-white border rounded shadow-md'>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: {
                            name: 'documentType',
                            value: 'NIC/Passport',
                          },
                        })
                      }
                    >
                      NIC/Passport
                    </div>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: { name: 'documentType', value: 'NIC' },
                        })
                      }
                    >
                      NIC
                    </div>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: { name: 'documentType', value: 'Passport' },
                        })
                      }
                    >
                      Passport
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-grow mb-8'>
                <input
                  className='w-full px-3 py-2 border rounded'
                  type='text'
                  id='identityNumber'
                  name='identityNumber'
                  placeholder='Identity Number'
                  value={formData.identityNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleClear}
              >
                Clear
              </button>
              <button
                className={`bg-blue-500 text-white px-4 py-2 rounded shadow-xl ${
                  !authvoucher ? 'bg-gray-200' : null
                }`}
                disabled={!authvoucher}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </>
        )}
        {/* end of first stage */}

        {/* 2nd Stage */}
        {aid === 2 && (
          <>
            <div className='mb-8 '>
              <div className='flex flex-col items-start shadow-lg  px-5 p-2'>
                <h1 className='text-purple-600 mb-2'>{user.type} Details</h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold text-left'>
                  Name: <span className='text-black ml-2'>{user.name}</span>
                </h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold'>
                  purpose:{' '}
                  <span className='text-black ml-2'>{user.purpose}</span>
                </h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold'>
                  Total Amount :{' '}
                  <span className='text-black ml-2'>
                    {user.total != 0 ? user.total : 'N/A'}
                  </span>
                </h1>
              </div>

              <div className='flex flex-col items-start mt-10 shadow-lg  px-5 p-2'>
                <h1 className='text-purple-600 mb-2'>payer Details</h1>
                <span className='font-[Rajdhani] text-blue-500 font-semibold'>
                  {' '}
                  Name:<span className='text-black ml-2'> {formData.name}</span>
                </span>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold'>
                  {formData.documentType}:{' '}
                  <span className=' text-black ml-2'>
                    {formData.identityNumber}
                  </span>
                </h1>
              </div>
              {/* <input
                className='w-full px-3 py-2 border rounded'
                type='text'
                id='name'
                name='name'
                placeholder='Name'
                value={formData.name}
                onChange={handleChange}
                required
              /> */}
            </div>
            {/* <div className='lg:flex gap-2'>
              <div className='relative mb-8 lg:w-[25%] bg-white rounded'>
                <div
                  className='w-full px-3 py-2 border rounded cursor-pointer text-left  '
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {formData.documentType}
                </div>
                {isDropdownOpen && (
                  <div className='absolute top-12 left-0 right-0 bg-white border rounded shadow-md'>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: {
                            name: 'documentType',
                            value: 'NIC/Passport',
                          },
                        })
                      }
                    >
                      NIC/Passport
                    </div>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: { name: 'documentType', value: 'nic' },
                        })
                      }
                    >
                      NIC
                    </div>
                    <div
                      className='p-2 cursor-pointer'
                      onClick={() =>
                        handleChange({
                          target: { name: 'documentType', value: 'passport' },
                        })
                      }
                    >
                      Passport
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-grow mb-8'>
                <input
                  className='w-full px-3 py-2 border rounded'
                  type='text'
                  id='identityNumber'
                  name='identityNumber'
                  placeholder='Identity Number'
                  value={formData.identityNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div> */}

            {/* <div className='mb-8 flex'>
              <input
                className='w-full  px-3 py-2 border rounded-l'
                type='text'
                id='voucherCode'
                name='voucherCode'
                placeholder='Voucher Code'
                value={formData.voucherCode}
                onChange={handleChange}
                required
              />
              <button
                className='bg-purple-500  text-white px-4 py-2 rounded-r'
                onClick={handleVerify}
              >
                Verify
              </button>
            </div> */}
            <div className='mb-10'>
              <input
                className='w-full px-3 py-2 border rounded'
                ref={payamountRef}
                type='number'
                id='payamount'
                name='payamount'
                placeholder='Pay Amount '
                value={formData.payamount}
                onChange={handleChange}
                required
              />
            </div>

            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleamountClear}
              >
                Clear
              </button>

              <button
                className='bg-blue-500 text-white px-4 py-2 rounded shadow-xl'
                onClick={handleBackHome}
              >
                Back
              </button>
              <button
                className='bg-blue-500 text-white px-4 py-2 rounded shadow-xl'
                onClick={handleNext2}
              >
                Next
              </button>
            </div>
          </>
        )}

        {/* end of 2nd stage */}

        {/* 3rd Stage */}
        {aid === 3 && (
          <>
            <div className='mb-8'>
              <div className='flex flex-col items-start shadow-lg px-5 p-2'>
                <h1 className='text-purple-600 mb-2'>{user.type} Details</h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold text-left'>
                  Name: <span className='text-black ml-2 '>{user.name}</span>
                </h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold'>
                  purpose:{' '}
                  <span className='text-black ml-2'>{user.purpose}</span>
                </h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold'>
                  Total Amount:{' '}
                  <span className='text-black ml-2'>
                    {user.total != 0 ? user.total : 'N/A'}
                  </span>
                </h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold text-left '>
                  Voucher Code :
                  <span className='text-black ml-2'>
                    {formData.voucherCode}
                  </span>
                </h1>
              </div>

              <div className='flex flex-col items-start mt-10 shadow-lg px-5 p-2'>
                <h1 className='text-purple-600 mb-2'>payer Details</h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold'>
                  {' '}
                  Name: <span className='text-black ml-2'>{formData.name}</span>
                </h1>
                <h1 className='font-[Rajdhani] font-semibold'>
                  <span className='text-blue-500'>
                    {formData.documentType}:
                  </span>{' '}
                  <span className=' ml-2'> {formData.identityNumber}</span>
                </h1>
                <h1 className='font-[Rajdhani] text-blue-500 font-semibold'>
                  Paying Amount :
                  <span className=' text-black ml-2'>{formData.payamount}</span>
                </h1>
              </div>
            </div>

            <div className='flex justify-between'>
              <button
                className='bg-red-500 text-white px-4 py-2 rounded shadow-lg'
                onClick={handleBack}
              >
                back
              </button>
              <button
                className='bg-blue-500  text-white px-4 py-2 rounded shadow-xl'
                onClick={paynow}
              >
                Pay now
              </button>
            </div>
          </>
        )}
        {/* end of 3rd stage */}

        {/* 4th Stage */}
        {aid === 4 && (
          <>
            <h1>payment status </h1>

            {payfailed && (
              <div className='rounded border p-5 border-red-700 m-5'>
                <div className='flex justify-center items-center '>
                  <i className='ri-creative-commons-sa-line text-red-500 mr-1 '></i>
                  <h1 className='text-red-500 font-[Rajdhani] font-semibold'>
                    {payfailed}
                  </h1>
                </div>
              </div>
            )}

            {paysuccess && (
              <div className='rounded border p-5 border-green-700 m-5'>
                <div className='flex justify-center items-center '>
                  <i className='ri-bank-card-2-line text-green-700 mr-1 '></i>

                  <h1 className='text-green-700 font-[Rajdhani] font-semibold'>
                    {paysuccess}
                  </h1>
                </div>
              </div>
            )}
          </>
        )}
        {/* end of 4th stage */}
      </div>
    </div>
  );
};

export default FormCard;
