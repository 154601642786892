import './App.css';
import { logo, uoj } from './Assets/index';
import FormCard from './Components/FormCard/FormCard';
import ProgressBar from './Components/ProgressBar/ProgressBar';

import { useProgressContext } from './ContextProvider/ProgressContext';

function App() {
  //const {progress, setProgress} = useProgressContext();

  return (
    <div className='App bg-[#E1E6FA] h-full '>
      <div className='mx-auto flex justify-center p-5 shadow-lg bg-[#5a78f0] '>
        <img
          className=' w-[500px]  p-2 px-20  rounded-lg bg-white shadow-lg '
          src={logo}
          alt='logo'
        />
      </div>
      <div className='  justify-center m-2 p-2 rounded-lg bg-white '>
        <h1
          className='font-DancingScript  mt-5 text-blue-600 text-2xl lg:text-4xl'
          style={{ textShadow: '3px 4px 3px #C0C0C0' }}
        >
          University of Jaffna Payment
        </h1>
       
        <ProgressBar />
      </div>
      <div
        className='m-2 rounded-lg shadow-lg '
        style={{ backgroundImage: `url(${uoj})`, backgroundSize: 'cover' }}
      >
        <div className='p-2 '>
          <FormCard />
        </div>
      </div>
      <div className='bg-white p-5 font-Agbalumo'>
        <h1>
          Copyright © 2024 - UOJ Payment - All Rights Reserved
        </h1>
      </div>
    </div>
  );
}

export default App;
